import { HTTP_METHOD } from "../../../helper/constants";
import request from "../../request";

const retailerMappingApi = {
  uploadRetailerMappingData: (body) => {
    const {action,formData}=body;
    const { name } = JSON.parse(sessionStorage.getItem("user_details")) || {};
    const url = `/retailer-onboarding/mapping?username=${name}&action=${action}`;
    return request({
      method: HTTP_METHOD.POST,
      url,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
  downloadRetailerMappingData: (body) => {
    const url = `/retailer-onboarding/mapping`;
    return request({
      method: HTTP_METHOD.GET,
      url,
    });
  },
  getRetailerMappingHistory: (payload) => {
    const {page=0}=payload;
    const url = `/retailer-onboarding/history?pageNo=${page}&pageSize=20`;
    return request({
      method: HTTP_METHOD.GET,
      url,
    });
  },
};

export default retailerMappingApi;
